<div class="qr-container">
  @if (qrCode !== '') {
    <!--        <img [src]="qrCode" width="100%">-->
    <qrcode [qrdata]="qrCode" [width]="450" [errorCorrectionLevel]="'M'" [colorLight]="'#000000'" [colorDark]="'#ffffff'"></qrcode>
  } @else {
    <div class="card-body background-color-custom">
      <div class="spinner-border loading" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="loading">
        Caricamento...
      </div>
    </div>
  }
  <ng-template #loading>
    <div class="card-body background-color-custom">
      <div class="spinner-border loading" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="loading">
        Caricamento...
      </div>
    </div>
  </ng-template>
  <button (click)="close()" class="btn btn-primary m-3">Chiudi</button>
</div>
