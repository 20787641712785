<div class="card background-color-custom">
  <div class="card-header background-color-custom modal-header">
    {{title}}
  </div>
  <div class="card-body background-color-custom modal-body">
      <div style="font-weight: bold" [innerHTML]="message">
      </div>
  </div>
  <div class="card-footer footer-buttons background-color-custom">
    @if (discardMessage && discardMessage !== '') {
      <button type="button" class="btn btn-primary m-2" mdbRipple (click)="discard()">
        {{discardMessage}}
      </button>
    }
    <button id="confirmButton" type="button" class="btn btn-primary m-2" mdbRipple (click)="confirm()">
      {{confirmMessage}}
    </button>
  </div>
</div>
