import {Component} from '@angular/core';
import {MdbModalRef} from 'mdb-angular-ui-kit/modal';

@Component({
	selector: 'app-loading-modal',
	templateUrl: './loading-modal.component.html',
	styleUrls: ['./loading-modal.component.scss'],
	standalone: true
})
export class LoadingModalComponent {

	constructor(private modalRef: MdbModalRef<LoadingModalComponent>) {
	}

	/**
	 * Chiude la modale
	 */
	close() {
		this.modalRef.close(true);
	}

}
