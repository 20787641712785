{
  "name": "photo-booth",
  "version": "1.0.0",
  "scripts": {
    "Avvia in locale NO SSL": "ng serve --no-ssl",
    "Avvia in locale SSL": "ng serve --ssl",
    "Use Local DEV": "node change_service_variables.js dev && firebase use dev",
    "Use DEV": "node change_service_variables.js dev local && firebase use dev",
    "Use PROD": "node change_service_variables.js prod && firebase use prod",
    "Build DEV": "ng build --configuration=development",
    "Build PROD": "ng build --configuration=production",
    "Deploy": "firebase deploy --only hosting"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.0",
    "@angular/cdk": "^18.0.0",
    "@angular/common": "^18.2.0",
    "@angular/compiler": "^18.2.0",
    "@angular/core": "^18.2.0",
    "@angular/fire": "^18.0.1",
    "@angular/forms": "^18.2.0",
    "@angular/platform-browser": "^18.2.0",
    "@angular/platform-browser-dynamic": "^18.2.0",
    "@angular/router": "^18.2.0",
    "@angular/service-worker": "^18.2.0",
    "@fortawesome/fontawesome-free": "^6.0.0",
    "angularx-qrcode": "^18.0.2",
    "face-api.js": "^0.22.2",
    "firebase": "^10.14.1",
    "firebase-tools": "^13.22.1",
    "mdb-angular-ui-kit": "git+https://oauth2:MTmDXEoyQn5VyRxFb1qz@git.mdbootstrap.com/mdb/angular/mdb5/prd/mdb5-angular-ui-kit-pro-essential",
    "ngx-device-detector": "^8.0.0",
    "rxjs": "~7.8.0",
    "socket.io-client": "^4.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.9",
    "@angular/cli": "^18.2.9",
    "@angular/compiler-cli": "^18.2.0",
    "@types/jasmine": "~5.1.0",
    "jasmine-core": "~5.2.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.5.2"
  }
}
