import {Component} from '@angular/core';
import {MdbModalRef} from 'mdb-angular-ui-kit/modal';

@Component({
	selector: 'app-alert-modal',
	templateUrl: './alert-modal.component.html',
	styleUrls: ['./alert-modal.component.scss'],
	standalone: true
})
export class AlertModalComponent {

	title: string = '';
	message: string = '';
	confirmMessage: string = '';
	discardMessage: string | undefined;

	constructor(private modalRef: MdbModalRef<AlertModalComponent>) {
	}

	/**
	 * Chiude la modale passando false
	 */
	discard() {
		this.modalRef.close(false);
	}

	/**
	 * Chiude la modale passando true
	 */
	confirm(){
		this.modalRef.close(true);
	}

}
