<div class="loading-modal-container background-color-custom loading-modal-body">
    <div style="font-weight: bold">
        Generazione immagini in corso...
    </div>
    <div class="spinner-border loading spinner-loading" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>
<div class="footer-buttons">
    <button (click)="close()" class="btn btn-primary m-3">Annulla</button>
</div>
