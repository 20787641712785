import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class SharedService {

	imgResult: string | undefined;
	deviceId: string | undefined;
	height = 768;
	width = 1024;
	thumbnails: string[] = [];
	frameSelected = "/assets/frame_giacapp1.png";
	timer = 10;
	inactivity = 60000;
	serverTooLong = 60000;
	facingMode: string | undefined;
	questions: string[] = [];
	themeSelected: number = 0;

	printEmail: string = '';
	settingsCode: string = '';

	experienceStarted = false;
	codeInserted = false;

	constructor() {
	}

	/**
	 * Converte un base64Url in base64
	 * @param url
	 */
	fromBase64UrlToBase64(url: string){
		return url.replace('data:image/jpeg;base64,', '');
	}

}
