import {inject, Injectable} from '@angular/core';
import {MdbModalRef, MdbModalService} from 'mdb-angular-ui-kit/modal';
import {QrCodeModalComponent} from '../core/modals/qr-code-modal/qr-code-modal.component';
import {AlertModalComponent} from '../core/modals/alert-modal/alert-modal.component';
import {LoadingModalComponent} from '../core/modals/loading-modal/loading-modal.component';
import {CodeModalComponent} from '../core/modals/code-modal/code-modal.component';
import {RotateModalComponent} from '../core/modals/rotate-modal/rotate-modal.component';
import {firstValueFrom} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ModalService {

	private modalService = inject(MdbModalService);

	private QrCodeModal: MdbModalRef<QrCodeModalComponent> | null = null;
	private alertModal: MdbModalRef<AlertModalComponent> | null = null;
	private alertModalOpened = false;
	private loadingModal: MdbModalRef<LoadingModalComponent> | null = null;
	private codeModal: MdbModalRef<CodeModalComponent> | null = null;
	private rotateModal: MdbModalRef<RotateModalComponent> | null = null;

	constructor() {
	}

	/**
	 * Apre la modale per mostrare il QRCode
	 * @param img
	 */
	openQrCodeModal(img: string) {
		this.QrCodeModal = this.modalService.open(QrCodeModalComponent, {
			modalClass: 'modal-dialog-centered modal-qr-code',
			ignoreBackdropClick: true,
			data: {
				qrCode: img,
			}
		});
	}

	/**
	 * Chiude la modale per mostrare il QRCode se è presente
	 */
	closeQrCodeModal(){
		if(this.QrCodeModal){
			this.QrCodeModal.close();
		}
	}

	/**
	 * Apre la modale di alert
	 * @param data
	 */
	openAlertModal(data: any){
		this.alertModal = this.modalService.open(AlertModalComponent, {
			modalClass: 'modal-dialog-centered modal-alert',
			ignoreBackdropClick: true,
			data
		});
	}

	/**
	 * Alla chiusura della modale di alert ricevo true o false
	 */
	onCloseConfirmModal(){
		if(this.alertModal){
			return this.alertModal.onClose;
		} else {
			return null;
		}
	}

	/**
	 * Chiude la modale di alert
	 */
	closeConfirmModal(){
		if(this.alertModal){
			this.alertModal.close();
		}
	}

	/**
	 * Funzione per aprire e gestire le conferme
	 * @param title
	 * @param message
	 * @param confirmMessage
	 * @param functionOnConfirm
	 * @param discardMessage
	 * @param functionOnDiscard
	 */
	requireConfirmation(title: string, message: string, confirmMessage: string, functionOnConfirm?: any, discardMessage?: string, functionOnDiscard?: any){
		if(!this.alertModal || !this.alertModalOpened){
			this.openAlertModal(
				{
					title, message, confirmMessage, discardMessage
				}
			);
			this.alertModalOpened = true;
			const obs = this.onCloseConfirmModal();
			if(obs){
				firstValueFrom(obs).then(res => {
					if(res && functionOnConfirm){
						functionOnConfirm();
					} else if(res === false && functionOnDiscard){
						functionOnDiscard();
					}
					this.alertModalOpened = false;
				});
			}
		}
	}

	/**
	 * Apre la modale per mostrare il loading per le immagini
	 */
	openLoadingModal() {
		this.loadingModal = this.modalService.open(LoadingModalComponent, {
			modalClass: 'modal-dialog-centered modal-lg',
			ignoreBackdropClick: true
		});
	}

	/**
	 * Chiude la modale per mostrare il loading per le immagini
	 */
	closeLoadingModal(){
		if(this.loadingModal){
			this.loadingModal.close();
		}
	}

	/**
	 * Alla chiusura della modale di loading ricevo true o false
	 */
	onCloseLoadingModal(){
		if(this.loadingModal){
			return this.loadingModal.onClose;
		} else {
			return null;
		}
	}

	/**
	 * Apre la modale per il codice per le impostazioni
	 */
	openCodeModal() {
		this.codeModal = this.modalService.open(CodeModalComponent, {
			modalClass: 'modal-dialog-centered modal-code',
			ignoreBackdropClick: true
		});
	}

	/**
	 * Alla chiusura della modale di loading ricevo true o false
	 */
	onCloseCodeModal(){
		if(this.codeModal){
			return this.codeModal.onClose;
		} else {
			return null;
		}
	}

	/**
	 * Apre la modale per richiedere la rotazione del dispositivo
	 */
	openRotateModal() {
		this.rotateModal = this.modalService.open(RotateModalComponent, {
			modalClass: 'modal-fullscreen',
			ignoreBackdropClick: true,
			animation: false
		});
	}

	/**
	 * Chiude la modale per richiedere la rotazione del dispositivo
	 */
	closeRotateModal(){
		if(this.rotateModal){
			this.rotateModal.close();
		}
	}

}
