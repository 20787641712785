<div class="card background-color-custom">
    <div class="card-header background-color-custom modal-header">
        Codice di accesso
    </div>
    <div class="card-body background-color-custom modal-body">
        <div class="mb-3" style="font-weight: bold;">
            Inserisci il codice di accesso alle impostazioni
        </div>
        <div style="display: flex; justify-content: center">
            <form [formGroup]="codeForm" style="width: 100%">
                <input type="text" id="code" class="form-control mb-2" formControlName="code" style="width: 100%;"/>
            </form>
        </div>
    </div>
    <div class="card-footer footer-buttons background-color-custom">
        <button type="button" class="btn btn-primary m-2" mdbRipple (click)="discard()">
            Annulla
        </button>
        <button id="confirmButton" type="button" class="btn btn-primary m-2" mdbRipple (click)="confirm()" [disabled]="codeForm.invalid">
            OK
        </button>
    </div>
</div>
