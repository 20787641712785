import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import * as i1 from '@angular/cdk/portal';
import { CdkPortalOutlet, ComponentPortal, TemplatePortal, PortalModule } from '@angular/cdk/portal';
import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Inject, ViewChild, TemplateRef, Injector, Injectable, NgModule } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import * as i1$1 from '@angular/cdk/overlay';
import { OverlayConfig, OverlayModule } from '@angular/cdk/overlay';

/* eslint-disable @typescript-eslint/no-inferrable-types */
function MdbNotificationContainerComponent_ng_template_1_Template(rf, ctx) {}
class MdbNotificationConfig {
  position = 'top-right';
  width = 'unset';
  delay = 5000;
  autohide = false;
  stacking = false;
  offset = 10;
  animation = true;
  viewContainerRef;
  data = null;
}
class MdbNotificationRef {
  overlayRef;
  _notificationService;
  _container;
  constructor(overlayRef, _notificationService, _container) {
    this.overlayRef = overlayRef;
    this._notificationService = _notificationService;
    this._container = _container;
  }
  component;
  onClose$ = new Subject();
  onClose = this.onClose$.asObservable();
  close(message) {
    this.onClose$.next(message);
    this.onClose$.complete();
    this._container._hidden.pipe(first()).subscribe(() => {
      this._notificationService.updateToast(this);
      this.overlayRef.detach();
      this.overlayRef.dispose();
    });
    this._container.animationState = 'hidden';
  }
  getPosition() {
    const overlayPosition = this.overlayRef.overlayElement;
    if (overlayPosition) {
      return overlayPosition.getBoundingClientRect();
    } else {
      return new DOMRect();
    }
  }
}
class MdbNotificationContainerComponent {
  _document;
  _elementRef;
  _renderer;
  _cdRef;
  _portalOutlet;
  _destroy$ = new Subject();
  _hidden = new Subject();
  _mouseleave = new Subject();
  animationState = 'visible';
  hover = false;
  config;
  constructor(_document, _elementRef, _renderer, _cdRef) {
    this._document = _document;
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    this._cdRef = _cdRef;
  }
  ngOnInit() {
    this._renderer.addClass(this._document.body, 'notification-open');
  }
  ngOnDestroy() {
    this._renderer.removeClass(this._document.body, 'notification-open');
  }
  attachComponentPortal(portal) {
    return this._portalOutlet.attachComponentPortal(portal);
  }
  attachTemplatePortal(portal) {
    return this._portalOutlet.attachTemplatePortal(portal);
  }
  detectChanges() {
    this._cdRef.detectChanges();
  }
  onAnimationEnd(event) {
    if (event.toState === 'hidden') {
      this._hidden.next();
    }
  }
  onMouseenter() {
    this.hover = true;
  }
  onMouseleave() {
    this.hover = false;
    this._mouseleave.next();
  }
  static ɵfac = function MdbNotificationContainerComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || MdbNotificationContainerComponent)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: MdbNotificationContainerComponent,
    selectors: [["mdb-notification-container"]],
    viewQuery: function MdbNotificationContainerComponent_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(CdkPortalOutlet, 7);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._portalOutlet = _t.first);
      }
    },
    decls: 2,
    vars: 1,
    consts: [[3, "mouseenter", "mouseleave"], ["cdkPortalOutlet", ""]],
    template: function MdbNotificationContainerComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵlistener("@fade.done", function MdbNotificationContainerComponent_Template_div_animation_fade_done_0_listener($event) {
          return ctx.onAnimationEnd($event);
        })("mouseenter", function MdbNotificationContainerComponent_Template_div_mouseenter_0_listener() {
          return ctx.config.autohide && ctx.onMouseenter();
        })("mouseleave", function MdbNotificationContainerComponent_Template_div_mouseleave_0_listener() {
          return ctx.config.autohide && ctx.onMouseleave();
        });
        i0.ɵɵtemplate(1, MdbNotificationContainerComponent_ng_template_1_Template, 0, 0, "ng-template", 1);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵproperty("@fade", ctx.animationState);
      }
    },
    dependencies: [i1.CdkPortalOutlet],
    encapsulation: 2,
    data: {
      animation: [trigger('fade', [state('visible', style({
        opacity: 1
      })), state('hidden', style({
        opacity: 0
      })), transition('visible => hidden', animate('150ms linear')), transition(':enter', [style({
        opacity: 0
      }), animate('150ms linear')])])]
    }
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MdbNotificationContainerComponent, [{
    type: Component,
    args: [{
      selector: 'mdb-notification-container',
      changeDetection: ChangeDetectionStrategy.Default,
      animations: [trigger('fade', [state('visible', style({
        opacity: 1
      })), state('hidden', style({
        opacity: 0
      })), transition('visible => hidden', animate('150ms linear')), transition(':enter', [style({
        opacity: 0
      }), animate('150ms linear')])])],
      template: "<div   \n  [@fade]=\"animationState\"\n  (@fade.done)=\"onAnimationEnd($event)\"\n  (mouseenter)=\"config.autohide && onMouseenter()\"\n  (mouseleave)=\"config.autohide && onMouseleave()\"\n>\n  <ng-template cdkPortalOutlet></ng-template>\n</div>\n"
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }, {
    type: i0.ChangeDetectorRef
  }], {
    _portalOutlet: [{
      type: ViewChild,
      args: [CdkPortalOutlet, {
        static: true
      }]
    }]
  });
})();
class MdbNotificationService {
  _overlay;
  _injector;
  _cfr;
  timeout;
  toasts = [];
  config;
  constructor(_overlay, _injector, _cfr) {
    this._overlay = _overlay;
    this._injector = _injector;
    this._cfr = _cfr;
  }
  open(componentOrTemplateRef, newConfig) {
    const defaultConfig = new MdbNotificationConfig();
    this.config = newConfig ? Object.assign(defaultConfig, newConfig) : defaultConfig;
    const overlayRef = this._createOverlay(this.config);
    const container = this._createContainer(overlayRef, this.config);
    const toastRef = this._createContent(componentOrTemplateRef, container, overlayRef, this.config);
    if (this.config.stacking) {
      this.toasts.push(toastRef);
    }
    if (this.config.autohide) {
      this.autohide(overlayRef, container, toastRef);
    }
    return toastRef;
  }
  autohide(overlayRef, container, toastRef) {
    this.timeout = setTimeout(() => {
      if (container.hover) {
        container._mouseleave.pipe(first()).subscribe(() => {
          this.autohide(overlayRef, container, toastRef);
        });
        return;
      }
      container._hidden.pipe(first()).subscribe(() => {
        if (this.config.stacking) {
          this.updateToast(toastRef);
        }
        overlayRef.detach();
        overlayRef.dispose();
      });
      container.animationState = 'hidden';
      container.detectChanges();
    }, this.config.delay);
  }
  updateToast(toastRef) {
    const toastIndex = this.toasts.indexOf(toastRef);
    this.toasts.splice(toastIndex, 1);
    this.toasts.forEach((toast, index) => {
      toast.overlayRef.updatePositionStrategy(this._getPositionStrategy(this.config, index - 1));
    });
  }
  _createOverlay(config) {
    const overlayConfig = this._getOverlayConfig(config);
    return this._overlay.create(overlayConfig);
  }
  _setOffset(config, index) {
    const verticalDirection = config.position.startsWith('top') ? 'bottom' : 'top';
    const shouldCalculateFromTop = verticalDirection === 'top' ? false : true;
    const calculationAdjustment = shouldCalculateFromTop ? 0 : window.innerHeight;
    if (this.toasts.length === 0 || index <= -1) {
      return config.offset;
    } else if (index || index === 0) {
      return Math.abs(calculationAdjustment - this.toasts[index].getPosition()[verticalDirection]);
    } else {
      return Math.abs(calculationAdjustment - this.toasts[this.toasts.length - 1].getPosition()[verticalDirection]);
    }
  }
  _getOverlayConfig(notificationConfig) {
    const width = notificationConfig.width;
    const config = new OverlayConfig({
      positionStrategy: this._getPositionStrategy(notificationConfig),
      scrollStrategy: this._overlay.scrollStrategies.reposition(),
      hasBackdrop: false,
      height: 'fit-content',
      width
    });
    return config;
  }
  _getPositionStrategy(notificationConfig, index) {
    const offset = `${this._setOffset(notificationConfig, index)}px`;
    let positionStrategy;
    switch (notificationConfig.position) {
      case 'top-left':
        positionStrategy = this._overlay.position().global().top(offset).left(`${notificationConfig.offset}px`);
        break;
      case 'bottom-left':
        positionStrategy = this._overlay.position().global().bottom(offset).left(`${notificationConfig.offset}px`);
        break;
      case 'bottom-right':
        positionStrategy = this._overlay.position().global().bottom(offset).right(`${notificationConfig.offset}px`);
        break;
      case 'bottom-center':
        positionStrategy = this._overlay.position().global().bottom(offset).centerHorizontally();
        break;
      case 'top-center':
        positionStrategy = this._overlay.position().global().top(offset).centerHorizontally();
        break;
      default:
        positionStrategy = this._overlay.position().global().top(offset).right(`${notificationConfig.offset}px`);
        break;
    }
    return positionStrategy;
  }
  _createContainer(overlayRef, config) {
    const portal = new ComponentPortal(MdbNotificationContainerComponent, null, this._injector, this._cfr);
    const containerRef = overlayRef.attach(portal);
    containerRef.instance.config = config;
    return containerRef.instance;
  }
  _createContent(componentOrTemplate, container, overlayRef, config) {
    const notificationRef = new MdbNotificationRef(overlayRef, this, container);
    if (componentOrTemplate instanceof TemplateRef) {
      container.attachTemplatePortal(new TemplatePortal(componentOrTemplate, null, {
        $implicit: config.data,
        notificationRef
      }));
    } else {
      const injector = this._createInjector(config, notificationRef, container);
      const contentRef = container.attachComponentPortal(new ComponentPortal(componentOrTemplate, config.viewContainerRef, injector));
      if (config.data) {
        Object.assign(contentRef.instance, {
          ...config.data
        });
      }
      notificationRef.component = contentRef.instance;
    }
    return notificationRef;
  }
  _createInjector(config, notificationRef, container) {
    const userInjector = config && config.viewContainerRef && config.viewContainerRef.injector;
    const providers = [{
      provide: MdbNotificationContainerComponent,
      useValue: container
    }, {
      provide: MdbNotificationRef,
      useValue: notificationRef
    }];
    return Injector.create({
      parent: userInjector || this._injector,
      providers
    });
  }
  static ɵfac = function MdbNotificationService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || MdbNotificationService)(i0.ɵɵinject(i1$1.Overlay), i0.ɵɵinject(i0.Injector), i0.ɵɵinject(i0.ComponentFactoryResolver));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: MdbNotificationService,
    factory: MdbNotificationService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MdbNotificationService, [{
    type: Injectable
  }], () => [{
    type: i1$1.Overlay
  }, {
    type: i0.Injector
  }, {
    type: i0.ComponentFactoryResolver
  }], null);
})();
class MdbNotificationModule {
  static ɵfac = function MdbNotificationModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || MdbNotificationModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: MdbNotificationModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [MdbNotificationService],
    imports: [OverlayModule, PortalModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MdbNotificationModule, [{
    type: NgModule,
    args: [{
      imports: [OverlayModule, PortalModule],
      exports: [MdbNotificationContainerComponent],
      declarations: [MdbNotificationContainerComponent],
      providers: [MdbNotificationService]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { MdbNotificationConfig, MdbNotificationContainerComponent, MdbNotificationModule, MdbNotificationRef, MdbNotificationService };
