import {Component} from '@angular/core';
import {MdbNotificationRef} from "mdb-angular-ui-kit/notification";

@Component({
	selector: 'app-success-alert',
	templateUrl: './success-alert.component.html',
	styleUrls: ['./success-alert.component.scss'],
	standalone: true
})
export class SuccessAlertComponent {

	message: string = '';

	constructor(private modalRef: MdbNotificationRef<SuccessAlertComponent>) {
	}

}
