import {Component} from '@angular/core';

@Component({
	selector: 'app-rotate-modal',
	templateUrl: './rotate-modal.component.html',
	styleUrls: ['./rotate-modal.component.scss'],
	standalone: true
})
export class RotateModalComponent {

	constructor() {
	}

}
