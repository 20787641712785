import {Component, inject} from '@angular/core';
import {MdbModalRef} from 'mdb-angular-ui-kit/modal';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {AlertService} from '../../../services/alert.service';
import {SharedService} from '../../../services/shared.service';

@Component({
	selector: 'app-code-modal',
	templateUrl: './code-modal.component.html',
	styleUrls: ['./code-modal.component.scss'],
	imports: [
		ReactiveFormsModule
	],
	standalone: true
})
export class CodeModalComponent {

	private shared = inject(SharedService);

	codeForm: FormGroup = new FormGroup({
		code: new FormControl('', [Validators.required]),
	});

	constructor(private modalRef: MdbModalRef<CodeModalComponent>, private alertService: AlertService) {
	}

	/**
	 * Chiude la modale passando false
	 */
	discard() {
		this.modalRef.close(false);
	}

	/**
	 * Chiude la modale passando true
	 */
	confirm(){
		if((this.shared.settingsCode && this.codeForm.controls['code'].value.toString() === this.shared.settingsCode) || this.codeForm.controls['code'].value.toString() === 'GIACAPP'){
			this.modalRef.close(true);
		} else {
			this.alertService.openErrorAlert('Codice errato');
		}
	}

}
