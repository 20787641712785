import {Component} from '@angular/core';
import {MdbNotificationRef} from "mdb-angular-ui-kit/notification";

@Component({
	selector: 'app-error-alert',
	templateUrl: './error-alert.component.html',
	styleUrls: ['./error-alert.component.scss'],
	standalone: true
})
export class ErrorAlertComponent {

	message: string = '';

	constructor(private modalRef: MdbNotificationRef<ErrorAlertComponent>) {
	}

}
