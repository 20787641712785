import {Component, OnInit} from '@angular/core';
import {MdbModalRef} from 'mdb-angular-ui-kit/modal';
import {QRCodeModule} from 'angularx-qrcode';

@Component({
	selector: 'app-qr-code-modal',
	templateUrl: './qr-code-modal.component.html',
	styleUrls: ['./qr-code-modal.component.scss'],
	standalone: true,
	imports: [
		QRCodeModule
	]
})
export class QrCodeModalComponent{

	qrCode: string = '';

	constructor(private modalRef: MdbModalRef<QrCodeModalComponent>) {
	}

	/**
	 * Chiude la modale
	 */
	close() {
		this.modalRef.close();
	}

}
