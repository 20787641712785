import {inject, Injectable} from '@angular/core';
import {MdbNotificationRef, MdbNotificationService} from "mdb-angular-ui-kit/notification";
import {SuccessAlertComponent} from "../core/alerts/success-alert/success-alert.component";
import {ErrorAlertComponent} from "../core/alerts/error-alert/error-alert.component";

@Injectable({
	providedIn: 'root'
})
export class AlertService {

	private notificationService = inject(MdbNotificationService);

	private successAlert: MdbNotificationRef<SuccessAlertComponent> | null = null;
	private errorAlert: MdbNotificationRef<ErrorAlertComponent> | null = null;

	constructor() {
	}

	/**
	 * Apre la notifica di success con il messaggio inserito
	 * @param message
	 */
	openSuccessAlert(message: string) {
		this.successAlert = this.notificationService.open(SuccessAlertComponent, {
			autohide: true,
			delay: 2000,
			stacking: true,
			data: {
				message
			}
		});
	}

	/**
	 * Apre la notifica di error con il messaggio inserito
	 * @param message
	 */
	openErrorAlert(message: string) {
		this.errorAlert = this.notificationService.open(ErrorAlertComponent, {
			autohide: true,
			delay: 2000,
			data: {
				message
			}
		});
	}

}
